<template>
    <div>
      <v-container>
        <base-material-card
          icon="mdi-clipboard-text"
          title="กำหนดการสำนักงานอาชีวศึกษาจังหวัด"
          class="px-5 py-3"
          color="info"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" lg="6" class="text-right">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />
              </v-col>
              <v-col cols="12" lg="6" class="text-right">
                <v-btn
                  large
                  right
                  depressed
                  color="primary"
                  @click.native="period_vecprovinceAdd()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="period_vecprovinces"
            :search="search"
          >
            <template v-slot:item.period_vecprovince_start="{ item }">
              {{
                item.period_vecprovince_start
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
            <template v-slot:item.period_vecprovince_stop="{ item }">
              {{
                item.period_vecprovince_stop
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
            <template v-slot:item.period_vecprovince_cal_end="{ item }">
              {{
                item.period_vecprovince_cal_end
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
            <template v-slot:item.period_vecprovince_enable="{ item }">
              <v-icon
                large
                color="green darken-2"
                v-if="item.period_vecprovince_enable === '1'"
                >mdi-alarm-light</v-icon
              >
              <v-icon v-if="item.period_vecprovince_enable === '0'"
                >mdi-alarm-light-outline</v-icon
              >
            </template>
  
            <template v-slot:item.period_vecprovince_enfile="{ item }">
              <v-icon
                large
                color="green darken-2"
                v-if="item.period_vecprovince_enfile === '1'"
                >mdi-alarm-light</v-icon
              >
              <v-icon v-if="item.period_vecprovince_enfile === '0'"
                >mdi-alarm-light-outline</v-icon
              >
            </template>
  
            <template v-slot:item.period_vecprovince_type="{ item }"> 
               <span v-if="item.period_vecprovince_type === 'discuss_teacher'"
                >พิจารณาย้ายสายการสอน</span
              >
              <span v-if="item.period_vecprovince_type === 'discuss_manage'"
                >พิจารณาย้ายสายบริหาร</span
              >
              <span v-if="item.period_vecprovince_type === 'verify_sedirectior'"
                >ตรวจสอบคุณสมบัติ สมัคร รองผู้อำนวยการ</span
              >
            </template>
  
            <template v-slot:item.actions="{ item }">
              <v-icon
                large
                color="yellow"
                @click.stop="period_vecprovinceEdit(item.period_vecprovince_year,item.period_vecprovince_times,item.period_vecprovince_type)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:item.action_s="{ item }">
              <v-icon
                color="red"
                large
                @click.stop="period_vecprovinceDelete(item.period_vecprovince_year,item.period_vecprovince_times,item.period_vecprovince_type)"
              >
                mdi-delete
              </v-icon>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card>
  
        <!--addperiod_vecprovincedialog  -->
        <v-layout row justify-center>
          <v-dialog v-model="addperiod_vecprovincedialog" persistent max-width="50%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เพิ่มข้อมูลกำหนดการ"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text>
                <v-form ref="addperiod_vecprovinceform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-select
                          v-model="addperiod_vecprovince.period_vecprovince_year"
                          :items="period_vecprovinceselect"
                          item-text="text"
                          item-value="value"
                          label="เลือกปีการศึกษา"
                          single-line
                        >
                        </v-select>
                        <v-spacer></v-spacer>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="number"
                          label="ครั้งที่"
                          v-model="addperiod_vecprovince.period_vecprovince_times"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-select
                          :items="period_vecprovince_types"
                          item-value="value"
                          label="ปรเภทการย้าย"
                          v-model="addperiod_vecprovince.period_vecprovince_type"
                          required
                          :rules="[v => !!v || '']"
                        ></v-select>
                      </v-flex>
  
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่เริ่ม"
                          v-model="addperiod_vecprovince.period_vecprovince_start"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่สิ้นสุด"
                          v-model="addperiod_vecprovince.period_vecprovince_stop"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="addperiod_vecprovince.period_vecprovince_enable_sw"
                          label="เปิดใช้งานสำนักงานอาชีวศึกษาจังหวัด"
                        ></v-switch>
                      </v-flex>
  
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="
                            addperiod_vecprovince.period_vecprovince_enfile_sw
                          "
                          label="เปิดใช้งานการแนบไฟล์"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  large
                  @click.stop="addperiod_vecprovincedialog = false"
                  rounded
                  ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="success"
                  @click.stop="addperiod_vecprovinceSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model deleteperiod_vecprovincedialog -->
        <v-layout>
          <v-dialog
            v-model="deleteperiod_vecprovincedialog"
            persistent
            max-width="40%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบข้อมูลปี"
                class="px-5 py-3 text_google"
              >
              </base-material-card>
  
              <v-card-text class="text_google">
                <v-card>
                  <v-card-text>
                    <v-form ref="deleteperiod_vecprovinceform" lazy-validation>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบรายการ {{ editperiod_vecprovince.period_vecprovince_year }} :
                            {{ editperiod_vecprovince.period_vecprovince_year }}
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large @click.stop="deleteperiod_vecprovincedialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deleteperiod_vecprovinceSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model editperiod_vecprovincedialog -->
        <v-layout row justify-center>
          <v-dialog v-model="editperiod_vecprovincedialog" persistent max-width="80%">
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขข้อมูลปี"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editperiod_vecprovinceform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-select
                          v-model="editperiod_vecprovince.period_vecprovince_year"
                          :items="period_vecprovinceselect"
                          item-text="text"
                          item-value="value"
                          label="เลือกปีการศึกษา"
                        >
                        </v-select>
                        <v-spacer></v-spacer>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="number"
                          label="ครั้งที่"
                          v-model="editperiod_vecprovince.period_vecprovince_times"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-select
                          :items="period_vecprovince_types"
                          item-value="value"
                          label="ปรเภทการย้าย"
                          v-model="editperiod_vecprovince.period_vecprovince_type"
                          required
                          :rules="[v => !!v || '']"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่เริ่ม"
                          v-model="editperiod_vecprovince.period_vecprovince_start"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่สิ้นสุด"
                          v-model="editperiod_vecprovince.period_vecprovince_stop"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="editperiod_vecprovince.period_vecprovince_enable_sw"
                          label="เปิดใช้งานสำนักงานอาชีวศึกษาจังหวัด"
                        ></v-switch>
                      </v-flex>
  
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="
                            editperiod_vecprovince.period_vecprovince_enfile_sw
                          "
                          label="เปิดใช้งานการแนบไฟล์"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editperiod_vecprovincedialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editperiod_vecprovinceSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          multi-line
          vertical
          top
          auto-height
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <br />
          {{ snackbar.text }}
          <v-btn dark @click="snackbar.show = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import VueMoment from "vue-moment";
  import moment from "moment-timezone";
  export default {
    name: "period_vecprovince",
    data() {
      return {
        VueMoment,
        moment,
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        addperiod_vecprovincedialog: false,
        editperiod_vecprovincedialog: false,
        deleteperiod_vecprovincedialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        period_vecprovince_types: [  
          { text: "พิจารณาย้ายสายการสอน", value: "discuss_teacher" },
          { text: "พิจารณาย้ายสายบริหาร", value: "discuss_manage" },
          { text: "ตรวจสอบคุณสมบัติ สมัคร รองผู้อำนวยการ", value: "verify_sedirectior" },
  
        ],
        period_vecprovinces: [],
        addperiod_vecprovince: {},
        editperiod_vecprovince: {},
        search: "",
        pagination: {},
        headers: [
          { text: "ครั้งที่", align: "center", value: "period_vecprovince_times" },
          { text: "ปี", align: "center", value: "period_vecprovince_year" },
          { text: "เริ่มวันที่", align: "center", value: "period_vecprovince_start" },
          {
            text: "สิ้นสุดวันที่",
            align: "center",
            value: "period_vecprovince_stop"
          },
  
          {
            text: "เกี่ยวข้องกับ",
            align: "center",
            value: "period_vecprovince_type"
          },
          {
            text: "สถานะรายงาน",
            align: "center",
            value: "period_vecprovince_enable"
          },
          {
            text: "สถานะแนบไฟล์",
            align: "center",
            value: "period_vecprovince_enfile"
          },
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
        period_vecprovinceselect: [
          {
            value: "2021",
            text: "2564"
          },
          {
            value: "2022",
            text: "2565"
          },
          {
            value: "2023",
            text: "2566"
          },
          {
            value: "2024",
            text: "2567"
          },
          {
            value: "2025",
            text: "2568"
          },
          {
            value: "2026",
            text: "2569"
          },
          {
            value: "2027",
            text: "2570"
          }
        ],
        data_syslog: {}
      };
    },
    async mounted() {
      this.period_vecprovinceQueryAll();
    },
    methods: {
      async period_vecprovinceQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("period_vecprovince.php", {
            ApiKey: this.ApiKey
          })
          .finally(() => (this.loading = false));
        this.period_vecprovinces = result.data;
      },
      async period_vecprovinceAdd() {
        this.addperiod_vecprovince = {};
        this.addperiod_vecprovince.period_vecprovince_year = new Date()
          .getFullYear()
          .toString();
        this.addperiod_vecprovince.period_vecprovince_start = new Date()
          .toISOString()
          .substr(0, 10);
        this.addperiod_vecprovince.period_vecprovince_stop = new Date()
          .toISOString()
          .substr(0, 10);
        this.addperiod_vecprovince.period_vecprovince_enable_sw = false;
  
        this.adddialog = true;
        this.addperiod_vecprovincedialog = true;
      },
  
  
      async addperiod_vecprovinceSubmit() {
        if (this.addperiod_vecprovince.period_vecprovince_enable_sw == true) {
          this.addperiod_vecprovince.period_vecprovince_enable = "1";
        } else {
          this.addperiod_vecprovince.period_vecprovince_enable = "0";
        }
  
           if (this.addperiod_vecprovince.period_vecprovince_enfile_sw == true) {
          this.addperiod_vecprovince.period_vecprovince_enfile = "1";
        } else {
          this.addperiod_vecprovince.period_vecprovince_enfile = "0";
        }
  
  
  
        this.addperiod_vecprovince.ApiKey = this.ApiKey;
  
        let result = await this.$http.post(
          "period_vecprovince.insert.php",
          this.addperiod_vecprovince
        );
        
        if (result.data.status == true) {
          Swal.fire({
              icon: 'success',
              title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
          this.period_vecprovinceQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "insert";
          this.data_syslog.page_log = "period_vecprovince";
          this.data_syslog.table_log = "period_vecprovince";
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
              icon: 'warning',
              title: 'ดำเนินการผิดพลาด',
              showConfirmButton: false,
              timer: 1500,
            })
        }
        this.addperiod_vecprovincedialog = false;
      },
  
      async period_vecprovinceEdit(period_vecprovince_year,period_vecprovince_times,period_vecprovince_type) {
        let result = await this.$http.post("period_vecprovince.php", {
          ApiKey: this.ApiKey,
          period_vecprovince_year: period_vecprovince_year,
          period_vecprovince_times: period_vecprovince_times,
          period_vecprovince_type: period_vecprovince_type,
        });
        this.editperiod_vecprovince = result.data;
        if (this.editperiod_vecprovince.period_vecprovince_enable == 1){
          this.editperiod_vecprovince.period_vecprovince_enable_sw = true;
        }  else{
         this.editperiod_vecprovince.period_vecprovince_enable_sw = false;      
        } 
  
          if (this.editperiod_vecprovince.period_vecprovince_enfile == 1){
          this.editperiod_vecprovince.period_vecprovince_enfile_sw = true;
        }  else{
         this.editperiod_vecprovince.period_vecprovince_enfile_sw = false;      
        } 
        this.editperiod_vecprovincedialog = true; 
        
      },
  
  
      async editperiod_vecprovinceSubmit() {
        if (this.editperiod_vecprovince.period_vecprovince_enable_sw == true){
        this.editperiod_vecprovince.period_vecprovince_enable = "1";  
        }             
        else{
         this.editperiod_vecprovince.period_vecprovince_enable = "0"; 
        } 
  
         if (this.editperiod_vecprovince.period_vecprovince_enfile_sw == true){
        this.editperiod_vecprovince.period_vecprovince_enfile = "1";  
        }             
        else{
         this.editperiod_vecprovince.period_vecprovince_enfile = "0"; 
        } 
  
  
        this.editperiod_vecprovince.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "period_vecprovince.update.php",
          this.editperiod_vecprovince
        );
        
        if (result.data.status == true) {
          this.period_vecprovince = result.data;
  
           Swal.fire({
              icon: 'success',
              title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
  
  
          this.period_vecprovinceQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "period_vecprovince";
          this.data_syslog.table_log = "period_vecprovince";
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
              icon: 'error',
              title: 'ดำเนินการผิดพลาด',
              showConfirmButton: false,
              timer: 1500,
            })
        }
        this.editperiod_vecprovincedialog = false;
      },
  
      async period_vecprovinceDelete(period_vecprovince_year,period_vecprovince_times,period_vecprovince_type) {
        let result = await this.$http.post("period_vecprovince.php", {
          ApiKey: this.ApiKey,
              period_vecprovince_year: period_vecprovince_year,
          period_vecprovince_times: period_vecprovince_times,
          period_vecprovince_type: period_vecprovince_type,
        });
        this.editperiod_vecprovince = result.data;
        this.deleteperiod_vecprovincedialog = true;
      },
  
      async deleteperiod_vecprovinceSubmit() {
        if (this.$refs.deleteperiod_vecprovinceform.validate()) {
          this.editperiod_vecprovince.ApiKey = this.ApiKey;
          let result = await this.$http.post(
            "period_vecprovince.delete.php",
            this.editperiod_vecprovince
          );
  
          if (result.data.status == true) {
            this.period_vecprovince = result.data;
            Swal.fire({
              icon: 'success',
              title: 'ดำเนินการลบข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
            this.period_vecprovinceQueryAll();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "delete";
            this.data_syslog.page_log = "period_vecprovince";
            this.data_syslog.table_log = "period_vecprovince";
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'ดำเนินการลบผิดพลาด',
              showConfirmButton: false,
              timer: 1500,
            })
          }
          this.deleteperiod_vecprovincedialog = false;
        }
      }
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  